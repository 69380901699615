@import "../../styles/_variables";

.item-sell-modal-box {
  .rodal-dialog {
    width: 600px!important;
    height: max-content!important;
    border-radius: 20px;

    @media screen and (max-width: 600px) {
      width: 100%!important;
    }
  }
}

.item-sell-modal-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  h3 {
    font-size: 27px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .item-id {
    margin-bottom: 20px;
    font-size: 20px;
    color: gray;
    font-weight: 600;
  }

  .item-sell-image-price {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    video, img {
      width: 250px;
      height: auto;
      max-height: 250px;
      border-radius: 20px;

      @media screen and (max-width: 600px) {
        width: 250px!important;
        max-height: 250px!important;
        margin: 0 auto 40px;
      }
    }

    .item-sell-price-wrapper {
      width: 350px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 600px) {
        width: 100%
      }

      .item-sell-price-actions {
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-bottom: 30px;

        .MuiInputBase-root {
          height: 35px;
        }

        .MuiFormControl-root {
          width: 100px;
        }

        .MuiOutlinedInput-adornedStart {
          padding-left: 0;
        }

        .MuiTextField-root {
          width: 100px;
          margin-right: 20px;
        }

        .custom-text-field {
          margin-bottom: 0;
        }
      }

      .sale-info {
        text-align: center;

        p {
          margin-bottom: 10px;
        }

        span {
          color: $main-light-color;
          font-weight: bold;
        }
      }

      .custom-button {
        width: 220px;
        margin-top: auto;
      }
    }
  }
}
