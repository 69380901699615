@import "../../styles/_variables";

.market-filters-wrapper {
  width: 30%;
  display: flex;
  flex-direction: column;
  padding: 200px 30px 50px 5%;
  justify-self: flex-end;

  @media screen and (max-width: 1024px) {
    width: 90%;
    margin: 0 auto;
    padding: 50px 0;
  }

  .MuiButtonBase-root {
    height: 50px;
  }

  .MuiAccordionSummary-content {
    img {
      margin-right: 15px;
    }

    p {
      font-weight: bold;
    }
  }

  .MuiAccordionDetails-root {
    display: flex;
    flex-direction: column;
  }

  .MuiAccordion-rounded {
    border-radius: 20px!important;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }

  .MuiAccordionSummary-root {
    border: 1px solid $secondary-dark-color;
    border-radius: 20px;
  }

  .MuiAccordionDetails-root {
    padding: 20px 0 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: initial;
  }

  .MuiIconButton-root {
    color: $secondary-dark-color;
  }

  .custom-text-field {
    .MuiOutlinedInput-input {
      padding: 6px;
    }
  }
}
